/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Roboto+Mono&family=Roboto&family=Anta&display=swap');

/* Variables */
:root {
    --accent: #7449bf;
    --title: black;
    --text: black;
    --text-light: #555555;
    --text-alt: white;
    --theme-font: 'Roboto Mono';
    --text-font: 'Poppins';
    --error-bg: 'red';
    --error-text: 'red';
}

.error {
    color: red !important;
    background-color: #ffcdcd !important;
    border: 1px solid red !important;
}

/* Body */
body::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
}

body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    height: 100vh;
}

#root {
    background-image: linear-gradient(to bottom right, #D2C4EA, #D5E3FF, #D2C4EA);
}

/* Whole app */
.App {
    box-sizing: border-box;
    margin: 0;
    /* font-family: 'Poppins'; */
    font-family: var(--theme-font);
}

/* Whole page */
.pages {
    margin: 0;
    padding: 0 20px 0 20px;
    min-height: 100vh;
}

.footer {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-family: var(--text-font);
    position: absolute;
    width: 100%;
    bottom: 0;
}

@media (max-width: 470px) {
    .footer {
        font-size: 12px;
    }
}

.footer-links a {
    padding-left: 6px;
    color: var(--accent);
}

.about-me {
    padding-top: 60px;
    position: relative;
    min-height: 100vh;
}

.contact-me {
    padding-top: 80px;
    position: relative;
    min-height: 100vh;
}

#home-header {
    opacity: 1;
    /* visibility: visible; */
    position: fixed;
    left: 0;
    top: 0;
    padding: 20px 20px 60px 20px;
    height: 100vh;
    width: 100%;
    text-align: center;
    z-index: 15;
}

#home-header.hide {
    transform: translateY(-50px);
    opacity: 0;
}

/*
.home-header img {
    width: 100%;
    height: 75%;
    opacity: .5;
} */

#home-header-words-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 40px);
}

#welcome {
    color: var(--text);
    font-size: 60px;
    /* margin: 40vh 0 10px 0; */
    display: inline-block;
    /* position: absolute;
    left: 0;
    top: 40%; */
}

#intro {
    color: var(--text);
    font-size: 24px;
    /* position: absolute;
    left: 0;
    bottom: 0; */
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 36px;
}

/* Navigation */
.top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 1s ease-in-out;
}

.bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all 1s ease-in-out;
}

nav {
    background-color: var(--accent);
    z-index: 16;
}

nav ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 20px;
    z-index: 18;
}

nav li {
    display: inline;
}

nav a {
    text-decoration: none;
    color: var(--text-alt);
    font-size: 36px;
    transition: 0.5s;
}

nav a:hover {
    font-size: 54px;
}

nav span {
    font-size: 48px !important;
    color: var(--text-alt);
    transition: 0.5s;
}

nav span:hover {
    font-size: 64px !important;
    cursor: pointer;
}

/* nav button {
    outline: none !important;
} */

/* All Content */
#content {
    position: sticky;
    z-index: 10;
    padding-top: 80px;
    padding-bottom: 60px;
    min-height: 100vh;
}

#content.show {
    display: block;
}

#content.hide {
    display: none;
}

/* Skills */
.skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill {
    position: relative;
    width: 160px;
    height: 200px;
    margin: 20px;
    /* background-color: aqua; */
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 8px rgba(0,0,0,.5);
    overflow: hidden;
    padding-bottom: 15px;
    background-color: white;
}

.skill.small {
    width: 280px;
    height: 160px;
}

.skill h2 {
    margin: 20px 0;
    top: 0;
    font-size: 24px;
}

.skill.dig-o h2 {
    font-size: 18px;
}

.skill img {
    width: 120px;
    height: 100px;
    margin: auto;
    /* width: 90%; */
    /* margin: 10px 5%; */
    object-fit: contain;
}

.skill.small img {
    width: 240px;
    height: 60px;
}

.level-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15px;
    background-color: lightgray;
}

.level-fifth {
    width: 20%;
    height: 100%;
    background-color: var(--accent);
    float: left;
}

/* Projects */
.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
}

.project-category {
    width: 28%;
    /* box-shadow: 0 0 8px rgba(0,0,0,.5); */
    text-align: center;
    padding: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.projects::-webkit-scrollbar {
    display: none;
}

.projects {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    box-shadow: 0 6px rgba(0,0,0,.1) inset, 0 -6px rgba(0,0,0,.1) inset;
    /* box-shadow: 0 -6px rgba(0,0,0,.1) inset; */
}

.project-category:hover {
    border-radius: 20px;
    box-shadow: 0 0 8px rgba(0,0,0,.5);
}

.project-category:hover h2 {
    scale: 110%;
}

/* Create four equal columns that sits next to each other */
.column {
    flex: 25%;
    max-width: 25%;
}

@media (max-width: 1600px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}

@media (max-width: 800px) {
    .column {
        flex: 100%;
        max-width: 100%;
    }
}

.project {
    margin: 20px 10px;
    padding: 20px;
    /* width: 80%; */
    border: 8px solid var(--accent);
    border-radius: 20px;
    overflow-wrap: break-word;
    text-align: center;
    box-shadow: 0 0 8px rgba(0,0,0,.5);
    background-color: white;
}

.project img {
    width: 90%;
    margin: 10px 5%;
    border: 4px solid black;
}

.project img:hover {
    scale: 105%;
}

.project h1 {
    font-size: 30px;
}

.project p {
    font-size: 18px;
    font-family: var(--text-font) !important;
}

/* Small version */
.techs {
    width: 90%;
    margin: auto;
    display: flex;
}

.techs .techs-label {
    display: none;
}

.techs .tech {
    position: relative;
    display: inline-flex;
    height: 12%;
    width: 12%;
}

.techs .tech .tech-icon {
    height: 90%;
    width: 90%;
    object-fit: contain;
    border: none;
}

.techs .tech .tech-icon:hover {
    scale: 100% !important;
}

.techs .tech .tech-title {
    display: none;
}

/* Expanded version */
.techs:hover {
    flex-direction: column;
}

.techs:hover .techs-label {
    display: block;
    text-align: left;
}

.techs:hover .tech {
    position: relative;
    display: inline-flex;
    height: 50px;
    width: 100%;
    vertical-align: middle;
}

.techs:hover .tech .tech-icon {
    height: 100%;
    width: 12%;
    object-fit: contain;
    margin: 0;
}

.techs:hover .tech .tech-icon:hover {
    scale: 100% !important;
}

.techs:hover .tech .tech-title {
    display: inline-flex;
    font-size: 18px;
    line-height: 50px;
    padding-left: 6px;
    color: var(--text-light);
}

/* About Me */
.main-container {
    width: 90%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 72px;
}

#about-title {
    font-size: 60px;
}

.about-section {
    display: flex;
    flex-wrap: wrap;
}

#me-img {
    /* width: 500px; */
    width: calc(100% - 712px);
    min-width: calc(40% - 12px);
    height: 600px;
    margin-right: 12px;
    object-fit: cover;
    border: 8px solid var(--accent);
    border-radius: 20px;
    box-shadow: 0 0 8px rgba(0,0,0,.5);
}

#me-par {
    width: 700px;
    max-width: 60%;
    font-family: var(--text-font);
    /* width: calc(100% - 512px); */
}

@media (max-width: 1000px) {
    #me-img {
        width: 100% !important;
        height: 300px;
        margin: 0;
    }
    #me-par {
        width: 100% !important;
        max-width: none;
    }
    .resume-item h2 {
        font-size: 24px;
    }
    .resume-item h3 {
        font-size: 18px;
    }
    .resume-item h4 {
        font-size: 16px;
    }
    .resume-item img {
        width: 100%;
    }
}

.info-card {
    height: 40px;
    font-size: 24px;
}

.info-card span {
    line-height: 40px;
    vertical-align: middle;
}

.info-card .info-card-icon {
    margin-right: 4px;
    color: var(--accent);
}

.resume-item {
    padding: 20px;
    border: 8px solid var(--accent);
    border-radius: 20px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,.5);
    background-color: white;
    font-family: var(--text-font) !important;
}

.resume-item h2,h3,h4,ul {
    margin-top: 0;
}

.resume-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    float: left;
    margin-right: 16px;
    padding: 6px;
    background-image: radial-gradient(lightgray, white, white);
}

.resume-img:after {
    clear: both;
}

.about-me p {
    font-size: 20px;
}

/* Contact Me */
#contact-title {
    font-size: 60px;
}

.contact-me p {
    font-size: 20px;
}

.accent-link {
    color: var(--accent);
    text-decoration: none;
    font-weight: bold;
}

#contact-form {
    background-color: var(--accent);
    width: 800px;
    max-width: 80%;
    min-width: 300px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0,0,0,.5);
}

#contact-form label {
    color: var(--text-alt);
    font-size: 30px;
}

#contact-form input {
    width: 100%;
    font-size: 24px;
    padding: 8px;
    border-radius: 8px;
    font-family: var(--theme-font);
    border: none;
}

#contact-form textarea {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-family: var(--theme-font);
    border: none;
}

#contact-form button {
    padding: 8px;
    font-size: 30px;
    font-family: var(--theme-font);
    border: none;
    box-shadow: 4px 4px 8px rgba(0,0,0,.5);
    border-radius: 8px;
    background-color: var(--text-alt);
    color: var(--accent);
    font-weight: bold;
    cursor: pointer;
}

#submit-form-message {
    color: white;
    margin-left: 20px;
    font-size: 20px;
}